import styled from 'styled-components'

interface StyledProps {
  showNavBar: boolean
}

export const NavContainer = styled.div`
  align-items: center;
  background-color: var(--navbar-blue);
  display: flex;
	position: relative;
  flex-direction: column;
  min-height: 100%;
  transition: min-width 300ms;
  min-width: 75px;

  @media (min-width: 1400px) {
    min-width: ${({ showNavBar }: StyledProps) => (showNavBar ? '325px' : '75px')};
  }
`

export const LogoWrapper = styled.div`
  margin-top: 25px;
  transition: width 300ms;
  width: 50px;

  @media (min-width: 1400px) {
    width: ${({ showNavBar }: StyledProps) => (showNavBar ? '141px' : '50px')};
  }
`

export const Nav = styled.nav`
  margin-top: 60px;
  width: 100%;
`

export const Ul = styled.ul`
  display: flex;
  flex-direction: column;

  svg {
    transform: scale(1.2);
    stroke-width: 1px;

    path {
      stroke: var(--white);
      stroke-width: 1px;
    }
  }

  .news {
    path {
      stroke-width: 20px;
    }
  }

  .gallery {
    path {
      stroke-width: 0.65px;
      fill: transparent;
    }
  }

  @media (min-width: 1400px) {
    svg {
      margin-right: ${({ showNavBar }: StyledProps) => (showNavBar ? '13px' : '0')};
    }
  }
`

export const NavButton = styled.button`
  display: none;

  @media (min-width: 1400px) {
    display: inline-block;
    background-color: transparent;
    border: 1px solid var(--white);
    border-radius: 50%;
    color: var(--white);
    cursor: pointer;
    margin: auto 0 25px 0;
    opacity: 0.6;
    outline: none;
    padding: 4px;
    transition: all 200ms ease-out;

    .chevron {
      font-size: 1.375rem;
      margin: ${({ showNavBar }: StyledProps) => (showNavBar ? '3px 2px 0 0' : '3px 0 0 2px')};
      transform: ${({ showNavBar }: StyledProps) => (showNavBar ? 'rotate(180deg)' : 'rotate(0deg)')};
      transition: transform 800ms;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
`

export const Divider = styled.div`
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  position: absolute;
  background-color: var(--white);
  left: ${({ showNavBar }: StyledProps) => (showNavBar ? '20px' : '0px')};
	right: ${({ showNavBar }: StyledProps) => (showNavBar ? '20px' : '0px')};

  @media (max-width: 600px) {
    height: 0.5px;
    margin-top: 10px;
    margin-bottom: 10px;
		
  }
`


export const LogoutButton = styled.button`
		display: flex;
		cursor: pointer;
		padding: 28px;
		width: 100%;
		align-items: center;
		background-color: var(--navbar-blue);
		color: var(--white);
		border: none;

	.button-text {
		font-size: 0;
		display: flex;
		align-items: center;
		gap: 0.5rem;
	}

	@media (min-width: 1400px) {
		.button-text {
			font-size: ${({ showNavBar }: StyledProps) => (showNavBar ? '1rem' : '0')};
			transition: ${({ showNavBar }: StyledProps) => showNavBar && 'font-size 300ms 100ms'};
		}
	}
`