import styled from 'styled-components'
import { IoMdArrowDropdown } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import useDropdown from '../../hooks/useDropdown'
import { useState, useEffect } from 'react'
import i18n from '../../i18n/i18n'


const LanguageDropdown = () => {
	const { t } = useTranslation()
	const { isOpen, dropdownRef, toggleDropdown } = useDropdown(false)
	const [language, setLanguage] = useState(localStorage.getItem('lng') || 'el')

	const switchLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
		localStorage.setItem("lng", lng)
    setLanguage(lng)
		toggleDropdown()
  }

	useEffect(() => {
    const storedLanguage = localStorage.getItem('lng');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      setLanguage(storedLanguage);
    }
  }, [])

  return (
    <DropdownWrapper ref={dropdownRef}>
      <DropdownButton onClick={toggleDropdown}>
        <DropdownLabel >
					<span className = 'button-text'>{t(language)}</span>
					<IoMdArrowDropdown className='dropdown-icon' />
        </DropdownLabel>
      </DropdownButton>
      <DropdownContent open={isOpen}>
				<DropdownItem onClick={() => switchLanguage('el')}>{t('el')}</DropdownItem>
				<DropdownItem onClick={() => switchLanguage('en')}>{t('en')}</DropdownItem>
      </DropdownContent>
    </DropdownWrapper>
  )
}

export default LanguageDropdown

const DropdownWrapper = styled.div`
  width: 80px;
	height: 40px;
	position: absolute;
	top: 1rem;
	right: 1rem;
	border-radius: 5px;
`;

const DropdownButton = styled.button`
  display: flex;
	border-radius: 5px;
  cursor: pointer;
	height: 100%;
  width: 100%;
	position: relative;
  align-items: center;
	font-weight: bold;
	text-transform: uppercase;
	justify-content: center;
  background-color: #00014E;
  color: var(--white);
  border: none;
`;

const DropdownLabel = styled.span`
  display: flex;
  align-items: center;

	.button-text {
		display: flex;
		align-items: center;
		font-size: 1rem;
	}

	.dropdown-icon {
    font-size: 1.1rem;
  }
`;

const DropdownContent = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: absolute;
	top: 100%;
  right: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
	border-radius: 5px;
`;

const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;
	border-radius: 5px;
	color: var(--navbar-blue);
	font-weight: bold;
	text-transform: uppercase;
	font-size: 1rem;
  &:hover {
    background-color: #edebeb;
  }
`;


