import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationEl from './el/translation.json'
import translationEn from './en/translation.json'

i18n
  .use(initReactI18next)
  .init({
    // debug: true,
    fallbackLng: 'el',
    lng: localStorage.getItem('lng') || 'el',
    resources: {
      el: {
        translation: translationEl
      },
			en: {
        translation: translationEn
      }
    }
  })

export default i18n

