import { configureStore } from '@reduxjs/toolkit'
import showModalReducer from './show-modal/show-modal-slice'
import authSliceReducer from './auth/auth-slice'
import mediaSlice from './media/media-slice'

export const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    modal: showModalReducer,
    media: mediaSlice,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
