import styled from 'styled-components'
import { IoLanguageOutline } from "react-icons/io5"
import { IoMdArrowDropdown } from 'react-icons/io'
import useDropdown from '../../hooks/useDropdown'
import i18n from '../../i18n/i18n'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'


interface StyledProps {
  showNavBar: boolean
}

const NavLanguageDropdown = (props: StyledProps) => {
  
	const { isOpen, dropdownRef, toggleDropdown } = useDropdown(false)
	const [language, setLanguage] = useState(localStorage.getItem('lng') || 'el')
	const { t } = useTranslation()

	const switchLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
		localStorage.setItem("lng", lng)
    setLanguage(lng)
		toggleDropdown()
  }

	useEffect(() => {
    const storedLanguage = localStorage.getItem('lng');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      setLanguage(storedLanguage);
    }
  }, [])


  return (
    <DropdownWrapper ref={dropdownRef}>
      <DropdownButton onClick={toggleDropdown}>
        <DropdownLabel showNavBar = {props.showNavBar}>
					<IoLanguageOutline size = {20}/>
          <span className = 'button-text'> {language === 'en' ? t('English') : t('Greek')}
					<IoMdArrowDropdown className='dropdown-icon' />
					</span>
        </DropdownLabel>
      </DropdownButton>
      <DropdownContent open={isOpen} showNavBar = {props.showNavBar}>
				<DropdownItem onClick={() => switchLanguage('el')}>{t('Greek')}</DropdownItem>
				<DropdownItem onClick={() => switchLanguage('en')}>{t('English')}</DropdownItem>
      </DropdownContent>
    </DropdownWrapper>
  )
}

export default NavLanguageDropdown

const DropdownWrapper = styled.div`
  width: 100%;
	padding: 28px;
	position: relative;
	margin-top: 28px;
`;

const DropdownButton = styled.button`
  display: flex;
  cursor: pointer;
  width: 100%;
  align-items: center;
  background-color: var(--navbar-blue);
  color: var(--white);
  border: none;
`;

const DropdownLabel = styled.span`
  display: flex;
  align-items: center;

	.button-text {
		font-size: 0;
		display: flex;
		align-items: center;
	}

	.dropdown-icon {
    font-size: 1.1rem;
  }


	@media (min-width: 1400px) {
		gap: ${({ showNavBar }: StyledProps) => (showNavBar ? '0.5rem' : '0px')};

		.button-text {
			font-size: ${({ showNavBar }: StyledProps) => (showNavBar ? '1rem' : '0')};
			transition: ${({ showNavBar }: StyledProps) => showNavBar && 'font-size 300ms 100ms'};
		}
	}
`;

const DropdownContent = styled.div<{ open: boolean, showNavBar: boolean }>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: absolute;
	z-index: 9999;
  background-color: #fff;
  border: 1px solid #ccc;
	border-radius: 5px;
	width: 100%;
	right: ${({ showNavBar }: StyledProps) => (showNavBar ? '10px' : '0px')};
	left: ${({ showNavBar }: StyledProps) => (showNavBar ? '45%' : '85%')};
	top: ${({ showNavBar }: StyledProps) => (showNavBar ? '30%' : '0px')};

	@media (min-width: 1400px) {
		width: ${({ showNavBar }: StyledProps) => (showNavBar ? '50%' : '100%')};
	}
`;

const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;
	border-radius: 5px;
	color: var(--navbar-blue);

  &:hover {
    background-color: #c5e2ed;
  }
`;
