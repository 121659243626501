import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface Props {
  logo: string
}

const Logo = ({ logo }: Props) => {
	const {t} = useTranslation()

  return <StyledLogo src={logo} alt={t('A logo which showing two seals')} />
}

export default Logo

const StyledLogo = styled.img`
  max-width: 100%;
`
