import styled, { css } from 'styled-components'

interface StyledProps {
  users?: boolean
  location?: boolean
}

export const StyledTitle = styled.h1`
  color: var(--navbar-blue);
  font-size: 1.25rem;

  @media (min-width: 1300px) {
    font-size: 1.5rem;
  }

  ${({ users, location }: StyledProps) => {
    if (users) {
      return css`
        font-size: 1.1875rem;
        font-weight: var(--regular);
        height: 100%;
        display: flex;
        align-items: center;
        &:nth-child(1) {
          width: 250px;
          border-right: 1px solid var(--border-gray);
        }
        &:nth-child(2) {
          width: 300px;
          border-right: 1px solid var(--border-gray);
          padding-left: 15px;
        }
        &:nth-child(3) {
          padding-left: 15px;
        }

        @media (min-width: 1300px) {
          font-size: 1.1875rem;
          &:nth-child(2) {
            padding-left: 25px;
          }
          &:nth-child(3) {
            padding-left: 25px;
          }
        }

        @media (min-width: 1400px) {
          &:nth-child(1) {
            width: 350px;
          }
          &:nth-child(2) {
            width: 350px;
          }
        }
      `
    }

    if (location) {
      return css`
        color: var(--dark-blue);
        font-size: 1rem !important;
        min-width: 170px;
        max-width: 170px;
        &:nth-child(1) {
          min-width: 90px;
          max-width: 90px;
        }

        @media (min-width: 1337px) {
          min-width: 204px;
          max-width: 204px;
        }
      `
    }
  }}
`
