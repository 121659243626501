import { useFormik } from 'formik'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import axios from '../../services/api'
import { StyledButton, StyledFormContainer, StyledInput } from '../LoginForm/LoginForm.styles'
import FormSpinner from '../UI/FormSpinner'
import { useTranslation } from 'react-i18next'

const PasswordReset = () => {
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
  const { token: resetPasswordToken } = useParams<{ token: string }>()
	const {t} = useTranslation()

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: yup.object({
      password: yup
        .string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
          t('Πρέπει να περιέχει 8 χαρακτήρες, έναν κεφαλαίο, έναν πεζό, έναν αριθμό και έναν ειδικό χαρακτήρα')
        )
        .required(' '),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], t('Οι κωδικοί πρέπει να ταιριάζουν'))
        .required(' '),
    }),
    onSubmit: async values => {
      try {
        setIsFormLoading(true)
        await axios.post('/auth/password-reset/', {
          password: values.password.trim(),
          token: resetPasswordToken,
        })
        toast.success(
          t('Ο κωδικός σας άλλαξε με επιτυχία, μπορείτε να συνδεθείτε στην εφαρμογή με τον νέο σας κωδικό πρόσβασης')
        )
        formik.resetForm()
      } catch (error) {
        toast.error(t('Αποτυχία επαναφοράς κωδικού'))
      } finally {
        setIsFormLoading(false)
      }
    },
  })

  return (
    <StyledFormContainer>
      <h1 className='form-title'>{t('Επαναφορά κωδικού')}</h1>
      <p className='form-paragraph'>{t('Εισάγετε νέο κωδικό πρόσβασης')}.</p>

      <form onSubmit={formik.handleSubmit}>
        <StyledInput
          type='password'
          label='Εισάγετε νέο κωδικό'
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          {...formik.getFieldProps('password')}
        />
        <StyledInput
          type='password'
          label='Επαλήθευση κωδικού'
          error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
          {...formik.getFieldProps('confirmPassword')}
        />

        <StyledButton type='submit'>{isFormLoading ? <FormSpinner /> : t('υποβολη')}</StyledButton>
      </form>
    </StyledFormContainer>
  )
}

export default PasswordReset
