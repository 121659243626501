import { useEffect } from 'react'
import Logo from '../../components/Logo/Logo'
import { StyledLogoWrapper, StyledMain } from '../LoginPage'
import PasswordReset from '../../components/PasswordReset/PasswordReset'
import blueLogo from '../../assets/mom-logo-blue.png'

const PasswordResetPage = () => {
  useEffect(() => {
    document.title = 'MOm - Password reset'
  }, [])

  return (
    <StyledMain>
      <StyledLogoWrapper>
        <Logo logo={blueLogo} />
      </StyledLogoWrapper>
      <PasswordReset />
    </StyledMain>
  )
}

export default PasswordResetPage
