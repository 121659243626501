import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Modal {
  showModal: boolean
  showGalleryModal: boolean
  showGalleryImage: boolean
  showConfirmDeleteModal: boolean
  imageId: number | null
}

const initialState: Modal = {
  showModal: false,
  showGalleryModal: false,
  showGalleryImage: false,
  showConfirmDeleteModal: false,
  imageId: null,
}

const showModalSlice = createSlice({
  name: 'show modal',
  initialState,
  reducers: {
    setShowModal(state) {
      state.showModal = !state.showModal
    },
    setShowGalleryModal(state) {
      state.showGalleryModal = !state.showGalleryModal
    },
    setShowGalleryImage(state) {
      state.showGalleryImage = true
    },
    setHideGalleryImage(state) {
      state.showGalleryImage = false
    },
    setShowConfirmDeleteModal(state) {
      state.showConfirmDeleteModal = !state.showConfirmDeleteModal
    },
    setImageId(state, action: PayloadAction<number | null>) {
      state.imageId = action.payload
    },
  },
})

export const {
  setShowModal,
  setShowGalleryModal,
  setShowGalleryImage,
  setHideGalleryImage,
  setShowConfirmDeleteModal,
  setImageId,
} = showModalSlice.actions

export default showModalSlice.reducer
