class TokenService {
  getLocalRefreshToken() {
    const token = JSON.parse(localStorage.getItem('token') || '')
    return token?.refresh
  }

  getLocalAccessToken() {
    const token = JSON.parse(localStorage.getItem('token') || '')
    return token?.access
  }

  updateLocalAccessToken(accesToken: string) {
    let token = JSON.parse(localStorage.getItem('token') || '')
    token.access = accesToken
    localStorage.setItem('token', JSON.stringify(token))
  }
}

const tokenService = new TokenService()
export default tokenService
