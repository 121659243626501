import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import tokenService from '../../services/tokenService'
import { AuthTypes, UserTypes } from './auth.types'

const token: any = localStorage.getItem('token')

const initialState: AuthTypes = {
  user: null,
  token: JSON.parse(token),
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<{ refresh: string; access: string }>) {
      localStorage.setItem('token', JSON.stringify(action.payload))
      state.token = JSON.parse(localStorage.getItem('token') || '')
    },
    logoutUser(state) {
      localStorage.removeItem('token')
      state.token = null
      state.user = null
    },
    setAuthClient(state, action: PayloadAction<UserTypes>) {
      state.user = action.payload
    },

    refreshToken(state) {
      state.token = tokenService.getLocalAccessToken()
    },
  },
})

export const { setToken, logoutUser, setAuthClient, refreshToken } = authSlice.actions

export default authSlice.reducer
