import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  :root {

  /* Colors */
  --white: #fff;
  --dark-text: #1a1a1a;
  
  --light-blue:#3873b0;
  --dark-blue: #2e6092;
  --navbar-blue: #1a3754;
  --border-gray: #efefef;
  --header-link-gray: #656565;

  --dark-text-modal: #000123;
  --gray-text-modal: #a5a5a5;

  --success: #22bb33;
  --error: #ff4136;
  --delete: #B20101;

  /* Post colors */

  --title: #000123;
  --author: #6a6767;

  /* Font weights */
  --regular: 400;
  --medium: 500;
  --bold: 700;
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'Arimo', sans-serif;
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  #root {
    height: 100vh;
    display: grid;
  }



`
export default GlobalStyles
