export const appRoutes = {
  login: '/',
  locations: '/locations',
  users: '/users',
  news: '/news',
  gallery: '/gallery',
  activateAcccount: '/activate-account',
  forgotPassword: '/forgot-password',
  passwordReset: '/password-reset',
  accountDeletion: '/account-deletion',
}

export const usersPaths = [
  { id: 1, path: '/users', text: 'Όλοι οι χρήστες' },
  { id: 2, path: '/users/admins', text: 'Admins' },
  { id: 3, path: '/users/observers', text: 'Παρατηρητές' },
  { id: 4, path: '/users/moderators', text: 'Moderators' },
]

export const newsPaths = [
  { id: 1, path: '/news', text: 'Όλα τα νέα' },
  { id: 2, path: '/news/published', text: 'Δημοσιευμένα' },
  { id: 3, path: '/news/drafts', text: 'Πρόχειρα' },
]

export const galleryPaths = [
  { id: 1, path: '/gallery', text: 'Όλα τα αρχεία' },
  { id: 2, path: '/gallery/photos', text: 'Φωτογραφίες' },
  // { id: 3, path: '/gallery/videos', text: 'Βίντεο' },
]
