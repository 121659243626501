import styled from 'styled-components'
import LoginForm from '../components/LoginForm/LoginForm'
import Logo from '../components/Logo/Logo'
import blueLogo from '../assets/mom-logo-blue.png'
import loginScreenBgImage from '../assets/login-screen.jpg'
import { useEffect } from 'react'
import LanguageDropdown from '../components/UI/LanguageDropdown'


const LoginPage = () => {
  useEffect(() => {
    document.title = 'MOm - Login'
  }, [])


  return (
    <StyledMain>
      <StyledLogoWrapper>
        <Logo logo={blueLogo} />
      </StyledLogoWrapper>
      <LoginForm />
			<LanguageDropdown /> 
    </StyledMain>
  )
}

export default LoginPage

export const StyledMain = styled.main`
  align-items: center;
  background: url(${loginScreenBgImage}) no-repeat bottom;
  background-size: 100% 70%;
  background-color: #fdfdfd;

  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 15px;
`

export const StyledLogoWrapper = styled.div`
  margin: 40px 0;
  width: 141px;
`
