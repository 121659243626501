import { useEffect } from 'react'
import ForgotPassword from '../../components/ForgotPassword/ForgotPassword'
import Logo from '../../components/Logo/Logo'
import { StyledLogoWrapper, StyledMain } from '../LoginPage'
import blueLogo from '../../assets/mom-logo-blue.png'
import LanguageDropdown from '../../components/UI/LanguageDropdown'

const ForgotPasswordPage = () => {
  useEffect(() => {
    document.title = 'MOm - Forgot password'
  }, [])
  return (
    <StyledMain>
      <StyledLogoWrapper>
        <Logo logo={blueLogo} />
      </StyledLogoWrapper>
      <ForgotPassword />
			<LanguageDropdown /> 
    </StyledMain>
  )
}

export default ForgotPasswordPage
