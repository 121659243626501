import { Suspense, lazy } from 'react'
import { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import useSWR from 'swr'
import { appRoutes } from './routes/routes'
import { StyledSpinner } from './components/UI/StyledSpinner'
import Layout from './containers/Layout'
import LoginPage from './pages/LoginPage'
import { useAppDispatch, useAppSelector } from './store/hooks'
import { getAuthClient } from './store/auth/auth-actions'
import { retryChunkLoad } from './helpers/retryChunkLoad'
import { getMedia } from './store/media/media-actions'
import ActivateAccountPage from './pages/public/ActivateAccountPage'
import ForgotPasswordPage from './pages/public/ForgotPasswordPage'
import PasswordResetPage from './pages/public/PasswordResetPage'
import { I18nextProvider, useTranslation } from 'react-i18next'
import i18n from './i18n/i18n'
import RoleGate from './components/UI/RoleGate'
import AccountDeletionPage from './pages/public/AccountDeletionPage'
const LocationsPage = lazy(() => retryChunkLoad(() => import('./pages/LocationsPage')))
const UsersPage = lazy(() => retryChunkLoad(() => import('./pages/UsersPage')))
const NewsPage = lazy(() => retryChunkLoad(() => import('./pages/NewsPage')))
const GalleryPage = lazy(() => retryChunkLoad(() => import('./pages/GalleryPage')))

const App = () => {
  const showHideNavValue = localStorage.getItem('value')
  const [showNavBar, setShowNavBar] = useState<boolean>(showHideNavValue !== 'false')
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const token = useAppSelector(state => state.auth.token)
  useSWR(token ? 'getAuthclient' : null, () => dispatch<any>(getAuthClient(t)))
  useSWR(token ? 'media' : null, () => dispatch<any>(getMedia(t)))
  const authUser = useAppSelector(state => state.auth.user)
  const allowedRole =
    authUser?.user_type === 'ADMINISTRATOR' && !authUser.is_moderator ? 'ADMINISTRATOR' : null

  return (
    <I18nextProvider i18n={i18n}>
      <Switch>
        <Route path={appRoutes.accountDeletion}>
          <AccountDeletionPage />
        </Route>
        <Route path={appRoutes.login} exact>
          <LoginPage />
        </Route>
        <Route path={`${appRoutes.activateAcccount}/:token`}>
          <ActivateAccountPage />
        </Route>
        <Route path={`${appRoutes.forgotPassword}`}>
          <ForgotPasswordPage />
        </Route>
        <Route path={`${appRoutes.passwordReset}/:token`}>
          <PasswordResetPage />
        </Route>
        <Layout showNavBar={showNavBar} setShowNavBar={setShowNavBar}>
          <Suspense fallback={<StyledSpinner />}>
            <Route path={appRoutes.locations}>
              <LocationsPage />
            </Route>
            <RoleGate allowedRole={allowedRole}>
              <Route path={appRoutes.users}>
                <UsersPage />
              </Route>
              <Route path={appRoutes.news}>
                <NewsPage showNavBar={showNavBar} />
              </Route>
              <Route path={appRoutes.gallery}>
                <GalleryPage />
              </Route>
            </RoleGate>
          </Suspense>
        </Layout>
      </Switch>
    </I18nextProvider>
  )
}

export default App
