import { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from '../../services/api'
import * as yup from 'yup'
import jwt from 'jwt-decode'
import { ImEye, ImEyeBlocked } from 'react-icons/im'
import { StyledFormContainer, StyledInput, StyledButton } from './LoginForm.styles'
import { InputAdornment } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setToken } from '../../store/auth/auth-slice'
import { appRoutes } from '../../routes/routes'
import FormSpinner from '../UI/FormSpinner'
import { useTranslation } from 'react-i18next'


interface LoginTypes {
  username: string
  password: string
}

interface DecodedTokenTypes {
  user_type: string
  admin_id: number
  observer_id: number
}

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const token = useAppSelector(state => state.auth.token)
	const {t} = useTranslation()
  // Show/Hide Password Handler
  const showPasswordHandler = () => setShowPassword(prevState => !prevState)

  // Focus for the email input on page load
  const inputFocus = useRef<HTMLInputElement>(null)
  useEffect(() => inputFocus.current?.focus(), [])

  const initialValues = {
    username: '',
    password: '',
  }

  const validationSchema = yup.object({
    username: yup.string().required(),
    password: yup.string().required(),
  })

  const onSubmit = async (values: LoginTypes) => {
    setIsFormLoading(true)
    try {
      const response = await axios.post('/login/', values, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const decodedToken: DecodedTokenTypes = jwt(response.data.data.access)
      if (decodedToken?.user_type === 'OBSERVER') {
        throw Error
      }

      dispatch(setToken(response.data.data))
      history.replace(appRoutes.locations)
      setIsFormLoading(false)
    } catch (err) {
      toast.error(t('Λανθασμένο όνομα χρήστη ή κωδικός πρόσβασης'))
      setIsFormLoading(false)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  useEffect(() => {
    if (token) history.replace(appRoutes.locations)
  }, [token, history])

  return (
    <StyledFormContainer>
      <h1 className='form-title'>{t('Καλώς όρισες')}</h1>
      <p className='form-paragraph'>
        {t('Εάν έχεις ήδη λογαριασμό, συνδέσου χρησιμοποιώντας το όνομα χρήστη σου')}
      </p>
      <form onSubmit={formik.handleSubmit}>
        <StyledInput
          type='text'
          label={t('Όνομα Χρήστη')}
          inputRef={inputFocus}
          error={formik.touched.username && Boolean(formik.errors.username)}
          {...formik.getFieldProps('username')}
        />

        <StyledInput
          type={`${showPassword ? 'text' : 'password'}`}
          label={t('Κωδικός')}
          error={formik.touched.password && Boolean(formik.errors.password)}
          {...formik.getFieldProps('password')}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                {!showPassword && <ImEye onClick={showPasswordHandler} className='show-password' />}
                {showPassword && <ImEyeBlocked onClick={showPasswordHandler} className='show-password' />}
              </InputAdornment>
            ),
          }}
        />
        <StyledButton>{isFormLoading ? <FormSpinner /> : t('εισοδος')}</StyledButton>
      </form>
      <Link to={appRoutes.forgotPassword} className='change-password'>
        {t('Ξέχασες τον κωδικό σου')}
      </Link>
    </StyledFormContainer>
  )
}

export default LoginForm
