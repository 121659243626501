import { ComponentType } from 'react'

export const retryChunkLoad = (
  component: () => Promise<{ default: ComponentType<any> }>,
  retriesLeft = 5,
  interval = 1000
): Promise<{ default: ComponentType<any> }> => {
  return new Promise((resolve, reject) => {
    component()
      .then(resolve)
      .catch((error: any) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error)
            return
          }

          retryChunkLoad(component, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}
