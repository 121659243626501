import { useState, useRef, useEffect } from 'react';

function useDropdown(initialIsOpen: boolean) {
  const [isOpen, setIsOpen] = useState<boolean>(initialIsOpen);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return { isOpen, dropdownRef, toggleDropdown };
}

export default useDropdown