import styled from 'styled-components'
import { toast, Slide } from 'react-toastify'
import { useAppSelector } from '../store/hooks'
import { Redirect } from 'react-router'
import Navigation from '../components/Navigation/Navigation'
import { appRoutes } from '../routes/routes'
import 'react-toastify/dist/ReactToastify.css'
interface Props {
  children?: React.ReactNode
  showNavBar: boolean
  setShowNavBar: (prevState: (value: boolean) => boolean) => void
}

toast.configure({
  position: 'top-center',
  autoClose: 3000,
  pauseOnFocusLoss: false,
  transition: Slide,
})

const Layout = ({ children, showNavBar, setShowNavBar }: Props) => {
  const token = useAppSelector(state => state.auth.token)

  if (!token) return <Redirect to={appRoutes.login} />

  return (
    <StyledLayoutContainer>
      <Navigation showNavBar={showNavBar} setShowNavBar={setShowNavBar} />
      {children}
    </StyledLayoutContainer>
  )
}

export default Layout

const StyledLayoutContainer = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`
