import styled, { css } from 'styled-components'
import { TextField } from '@mui/material'

const borderRadius = css`
  border-radius: 5px;
`

export const StyledFormContainer = styled.div`
  background-color: var(--white);
  border: 1px solid #defaff;
  ${borderRadius};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 80px;
  max-width: 465px;

  .form-title {
    color: var(--dark-blue);
    font-size: 2.75rem;
    margin-bottom: 18px;
    margin-left: -3px;
  }

  .form-paragraph {
    font-size: 0.875rem;
    line-height: 1.2rem;
    max-width: 292px;
    margin-bottom: 22px;
  }

  .change-password {
    color: #1f54c3;
    margin: 27px 0 0 0;
  }
`

export const StyledInput = styled(TextField)`
  font-family: inherit;
  width: 100%;
  background-color: transparent;
  margin-bottom: 18px;

  /* Label */
  .MuiFormLabel-root {
    font-size: 0.9063rem;
    font-family: inherit;
    margin-left: 5px;

    &.Mui-focused {
      color: var(--dark-blue);
    }
  }

  /* Input */
  .MuiInputBase-root {
    ${borderRadius};
    input {
      font-size: 1rem;
      padding-left: 20px;
    }
  }

  /* Fieldset */
  .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: var(--dark-blue);
    }
    & fieldset {
      border-color: #efefef;
    }
    &:hover fieldset {
      border-color: var(--dark-blue);
    }
  }

  /* Error message */
  .MuiFormHelperText-root {
    margin-left: 2px;
  }

  /* Show/Hide password icon */
  .show-password {
    color: #cccccc;
    transform: scale(1.5);
    cursor: pointer;
    margin-right: 10px;
    transition: color 200ms;

    &:hover {
      color: var(--dark-blue);
    }
  }
`

export const StyledButton = styled.button`
  background: transparent linear-gradient(19deg, var(--dark-blue) 0%, var(--light-blue) 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 6px 20px rgba(31, 84, 195, 0.15);
  border: none;
  ${borderRadius};
  display: block;
  color: var(--white);
  cursor: pointer;
  font-size: 1rem;
  font-family: inherit;
  outline: none;
  text-transform: uppercase;
  width: 100%;
  min-height: 52px;
  transition: opacity 200ms ease-out;

  &:hover,
  &:focus {
    opacity: 0.9;
  }
`
