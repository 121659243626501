import { AppDispatch } from '../store'
import jwt from 'jwt-decode'
import axios from '../../services/api'
import { setAuthClient } from './auth-slice'
import { toast } from 'react-toastify'
import tokenService from '../../services/tokenService'
import { TFunction } from 'i18next'


interface DecodedTokenTypes {
  user_type: string
  admin_id: number
  observer_id: number
}

export const getAuthClient = (t: TFunction<"translation", undefined>) => {
  return async (dispatch: AppDispatch) => {
    const token = tokenService.getLocalAccessToken()
    const decodedToken: DecodedTokenTypes = jwt(token)
    try {
      const response = await axios.get(`/auth/administrator/${decodedToken?.admin_id}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
			const userDataWithModeratorStatus = {
				...response.data.data.user,
				is_moderator: response.data.data.is_moderator
			}
      dispatch(setAuthClient(userDataWithModeratorStatus))
    } catch (err) {
      toast.error(t('Αποτυχία φόρτωσης χρήστη'))
    }
  }
}
