import { useEffect } from 'react'
import ActivateAccount from '../../components/ActivateAccount/ActivateAccount'
import Logo from '../../components/Logo/Logo'
import { StyledLogoWrapper, StyledMain } from '../LoginPage'
import blueLogo from '../../assets/mom-logo-blue.png'
import LanguageDropdown from '../../components/UI/LanguageDropdown'

const ActivateAccountPage = () => {
  useEffect(() => {
    document.title = 'MOm - Activate account'
  }, [])

  return (
    <StyledMain>
      <StyledLogoWrapper>
        <Logo logo={blueLogo} />
      </StyledLogoWrapper>
      <ActivateAccount />
			<LanguageDropdown />
    </StyledMain>
  )
}

export default ActivateAccountPage
