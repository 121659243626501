import { toast } from 'react-toastify'
import axios from '../../services/api'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { StyledButton, StyledInput } from '../LoginForm/LoginForm.styles'
import { useTranslation } from 'react-i18next'

const ResendActivationLink = () => {

	const {t} = useTranslation()

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object({
      email: yup.string().email().required(),
    }),
    onSubmit: async values => {
      try {
        await axios.post('/auth/sign-up-reset/', { email: values.email.trim() })
        toast.success(t('Activation link has been sent to your email'))
      } catch (error) {
        toast.error(t('User with this email does not exist'))
      }
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <StyledInput
        placeholder={t('Enter your email')}
        error={formik.touched.email && Boolean(formik.errors.email)}
        {...formik.getFieldProps('email')}
      />
      <StyledButton type='submit'>{t('Resend Link')}</StyledButton>
    </form>
  )
}

export default ResendActivationLink
