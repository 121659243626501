import { useEffect } from 'react'
import Logo from '../../components/Logo/Logo'
import { StyledLogoWrapper, StyledMain } from '../LoginPage'
import blueLogo from '../../assets/mom-logo-blue.png'

// This page is only for Google play store to approve the app

const AccountDeletionPage = () => {
  useEffect(() => {
    document.title = 'MOm - Account deletion'
  }, [])

  const styles: any = {
    body: {
      color: '#2e6092',
      margin: 0,
      padding: 0,
    },
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      textAlign: 'center',
    },
    h1: {
      color: '#2e6092',
    },
    p: {
      fontSize: '1.2em',
    },
    instructions: {
      border: '2px solid #2e6092',
      borderRadius: '10px',
      padding: '20px',
      backgroundColor: '#ffffff',
    },
  }

  return (
    <StyledMain>
      <StyledLogoWrapper>
        <Logo logo={blueLogo} />
      </StyledLogoWrapper>
      <div style={styles.body}>
        <div style={styles.container}>
          <h1 style={styles.h1}>Οδηγίες Διαγραφής Λογαριασμού</h1>
          <div style={styles.instructions}>
            <p style={styles.p}>
              Για να διαγράψετε τον λογαριασμό σας στην εφαρμογή, ακολουθήστε τα εξής βήματα:
            </p>
            <ol>
              <li>Μεταβείτε στο προφίλ σας.</li>
              <li>Πατήστε το κουμπί "Διαγραφή του λογαριασμού μου".</li>
            </ol>
          </div>
        </div>

        <div style={styles.container}>
          <h1 style={styles.h1}>Delete Account Instructions</h1>
          <div style={styles.instructions}>
            <p style={styles.p}>To delete your account in the mobile app, follow the steps below:</p>
            <ol>
              <li>Go to your profile.</li>
              <li>Press the button "Delete my account".</li>
            </ol>
          </div>
        </div>
      </div>
    </StyledMain>
  )
}

export default AccountDeletionPage
