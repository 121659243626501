interface Props {
  data: any
  asc?: string | undefined
  desc?: string | undefined
}

export const sortDates = ({ data, asc, desc }: Props) => {
  if (asc) {
    return data.sort((a: { created_date: string }, b: { created_date: string }) =>
      a.created_date.localeCompare(b.created_date)
    )
  }

  if (desc) {
    return data.sort((a: { created_date: string }, b: { created_date: string }) =>
      b.created_date.localeCompare(a.created_date)
    )
  }
}
