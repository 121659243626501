import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'

import { Provider } from 'react-redux'
import { store } from './store/store'
import setupInterceptor from './services/setupInterceptor'
import GlobalStyles from './global-styles'


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <GlobalStyles />
        <App />
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

setupInterceptor(store.dispatch)
