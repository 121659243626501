import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { appRoutes } from '../../routes/routes'
import axios from '../../services/api'
import { useAppSelector } from '../../store/hooks'
import { StyledButton, StyledFormContainer, StyledInput } from '../LoginForm/LoginForm.styles'
import FormSpinner from '../UI/FormSpinner'
import { useTranslation } from 'react-i18next'

const ForgotPassword = () => {
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)
  const history = useHistory()
  const token = useAppSelector(state => state.auth.token)
	const {t} = useTranslation()

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object({
      email: yup.string().email().required(),
    }),
    onSubmit: async values => {
      try {
        setIsFormLoading(true)
        await axios.post('auth/password-reset-request/', { email: values.email.trim() })
        toast.success(t('Ένας σύνδεσμος για επαναφορά κωδικού πρόσβασης έχει σταλεί στο email σας'))
        history.replace(appRoutes.login)
      } catch (error) {
        toast.error(t('Δεν βρέθηκε λογαριασμός με αυτή την διεύθυνση email'))
      } finally {
        setIsFormLoading(false)
      }
    },
  })

  useEffect(() => {
    if (token) history.replace(appRoutes.locations)
  }, [token, history])

  return (
    <StyledFormContainer>
      <h1 className='form-title'>{t('Ξεχάσατε τον κωδικό σας;')}</h1>
      <p className='form-paragraph'>{t('Εισάγετε το email σας για να επαναφέρετε τον κωδικό σας')}</p>
      <form onSubmit={formik.handleSubmit}>
        <StyledInput
          type='email'
          label={t('Εισάγετε το email')}
          error={formik.touched.email && Boolean(formik.errors.email)}
          {...formik.getFieldProps('email')}
        />

        <StyledButton type='submit'>{isFormLoading ? <FormSpinner /> : t('υποβολη')}</StyledButton>
      </form>
    </StyledFormContainer>
  )
}

export default ForgotPassword
