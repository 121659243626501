import axios from 'axios'

const apiUrl =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_HOST_STAGING
    : process.env.REACT_APP_API_HOST_PRODUCTION

const axiosInstance = axios.create({
  baseURL: apiUrl,
})

export default axiosInstance
