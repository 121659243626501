import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import axios from '../../services/api'
import { FaRegCheckCircle, FaRegTimesCircle } from 'react-icons/fa'
import { StyledFormContainer } from '../LoginForm/LoginForm.styles'
import { StyledTitle } from '../UI/StyledTitle'
import { StyledSpinner } from '../UI/StyledSpinner'
import ResendActivationLink from './ResendActivationLink'
import { useTranslation } from 'react-i18next'

const ActivateAccount = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [apiError, setApiError] = useState<string | null>(null)
  const { token: activationToken } = useParams<{ token: string }>()
  const { t } = useTranslation()

  const submitActivationToken = useCallback(async () => {
    try {
      await axios.post('/auth/activate/', { token: activationToken })
    } catch (error) {
      setApiError(t('Failed to activate account'))
    } finally {
      setIsLoading(false)
    }
  }, [activationToken, t])

  useEffect(() => {
    if (activationToken) {
      submitActivationToken()
    }
  }, [activationToken, submitActivationToken, t])

  return (
    <StyledFormContainer>
      <StyledActivationContainer>
        <StyledTitle>{t('Account Activation')}</StyledTitle>
        {isLoading ? (
          <StyledSpinner />
        ) : apiError ? (
          <div className='message-container'>
            <p className='message'>{apiError}</p>
            <FaRegTimesCircle />
            <ResendActivationLink />
          </div>
        ) : (
          <div className='message-container'>
            <p className='message'>{t('Account activated successfully')}</p>
            <FaRegCheckCircle />
          </div>
        )}
      </StyledActivationContainer>
    </StyledFormContainer>
  )
}

export default ActivateAccount

const StyledActivationContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
  text-align: center;
  .message-container {
    margin: auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    svg {
      color: var(--dark-blue);
      font-size: 2.5rem;
    }
  }

  .message {
    font-size: 1.0625rem;
    font-weight: 500;
    margin: auto 0;
  }
`
