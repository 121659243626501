import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GalleryProperties } from '../../interfaces/gallery'

interface MediaTypes {
  allMedia: GalleryProperties[]
  publicPhotos: GalleryProperties[]
  privatePhotos: GalleryProperties[]
}

const initialState: MediaTypes = {
  allMedia: [],
  publicPhotos: [],
  privatePhotos: [],
}

const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    setAllMedia(state, action: PayloadAction<GalleryProperties[]>) {
      state.allMedia = action.payload
    },
    setPubliPhotos(state, action: PayloadAction<GalleryProperties[]>) {
      state.publicPhotos = action.payload
    },
    setPrivatePhotos(state, action: PayloadAction<GalleryProperties[]>) {
      state.privatePhotos = action.payload
    },
  },
})

export const { setAllMedia, setPubliPhotos, setPrivatePhotos } = mediaSlice.actions

export default mediaSlice.reducer
