import { useTranslation } from 'react-i18next'
import { NavLink as Link } from 'react-router-dom'
import styled from 'styled-components'

interface Props {
  children: React.ReactNode
  path: string
  linkText: string
  showNavBar: boolean
}

interface StyledProps {
  showNavBar: boolean
}

const NavLink = ({ children, path, linkText, showNavBar }: Props) => {

	const {t} = useTranslation()
  return (
    <StyledList showNavBar={showNavBar}>
      <Link to={path} activeClassName='active'>
        {children}
        <span className='link-text'>{t(linkText)}</span>
      </Link>
    </StyledList>
  )
}

export default NavLink

const StyledList = styled.li`
  a {
    display: flex;
    color: var(--white);
    cursor: pointer;
    padding: 28px;
    width: 100%;

    .link-text {
      font-size: 0;
    }

    @media (min-width: 1400px) {
      .link-text {
        font-size: ${({ showNavBar }: StyledProps) => (showNavBar ? '1rem' : '0')};
        transition: ${({ showNavBar }: StyledProps) => showNavBar && 'font-size 300ms 100ms'};
      }
    }
  }

  .active {
    animation: activeLink 200ms ease-in forwards;
    background: transparent linear-gradient(180deg, var(--dark-blue) 0%, var(--light-blue) 100%) 0% 0%
      no-repeat padding-box;
    border-radius: 0px 5px 5px 0px;

    @keyframes activeLink {
      0% {
        opacity: 0.5;
        width: 101%;
      }

      100% {
        opacity: 1;
        width: 102%;
      }
    }
  }
`
