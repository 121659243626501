import { AppDispatch } from '../store'
import axios from '../../services/api'
import { toast } from 'react-toastify'
import tokenService from '../../services/tokenService'
import { sortDates } from '../../helpers/sortDates'
import { setAllMedia, setPrivatePhotos, setPubliPhotos } from './media-slice'
import { TFunction } from 'i18next'

export const getMedia = (t:TFunction<"translation", undefined>) => {
  return async (dispatch: AppDispatch) => {
    const token = tokenService.getLocalAccessToken()

    try {
      const response = await axios.get('/gallery/gallery-item/', {
        headers: { Authorization: `Bearer ${token!.access || token}` },
      })

      const media = sortDates({ data: response.data.data, desc: 'desc' })

      const filteredPublicImages = media.filter(
        (file: { media_type: string; private: boolean }) =>
          file.media_type === 'IMAGE' && file.private === false
      )

      const filteredPrivateImages = media.filter(
        (file: { media_type: string; private: boolean }) =>
          file.media_type === 'IMAGE' && file.private === true
      )

      dispatch(setAllMedia(media))
      dispatch(setPrivatePhotos(filteredPrivateImages))
      dispatch(setPubliPhotos(filteredPublicImages))
    } catch (error) {
      toast.error(t('Αποτυχία φόρτωσης πολυμέσων'))
    }
  }
}
