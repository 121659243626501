import Logo from '../Logo/Logo'
import NavLink from './NavLink/NavLink'
import * as Styled from './Navigation.styles'
import { GrMap } from 'react-icons/gr'
import { FiUsers } from 'react-icons/fi'
import { IoNewspaperOutline } from 'react-icons/io5'
import { BsCardImage, BsChevronRight } from 'react-icons/bs'
import logoWhite from '../../assets/mom-logo-white.png'
import { appRoutes } from '../../routes/routes'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FiLogOut } from 'react-icons/fi'
import { logoutUser } from '../../store/auth/auth-slice'
import NavLanguageDropdown from './NavLanguageDropdown'
import { useAppSelector } from '../../store/hooks'
import RoleGate from '../UI/RoleGate'



interface Props {
  showNavBar: boolean
  setShowNavBar: (prevState: (value: boolean) => boolean) => void
}

const Navigation = ({ showNavBar, setShowNavBar }: Props) => {
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const authUser = useAppSelector(state => state.auth.user) 
	const allowedRole = (authUser?.user_type === 'ADMINISTRATOR' && !authUser.is_moderator) ? 'ADMINISTRATOR' : null

  // Show/Hide Navigation Bar Handler
  localStorage.setItem('value', showNavBar === true ? 'true' : 'false')
  const navBarHandler = () => {
    setShowNavBar(prevState => !prevState)
    localStorage.setItem('value', showNavBar === true ? 'true' : 'false')
  }

	const logoutHandler = () => {
			dispatch(logoutUser())
			toast.success(t('Αποσυνδεθήκατε επιτυχώς'))
	}

  return (
    <Styled.NavContainer showNavBar={showNavBar}>
      <Styled.LogoWrapper showNavBar={showNavBar}>
        <Logo logo={logoWhite} />
      </Styled.LogoWrapper>
      <Styled.Nav>
        <Styled.Ul showNavBar={showNavBar}>
          <NavLink
            path={appRoutes.locations}
            linkText={t('Χάρτης Περιστατικών')}
            showNavBar={showNavBar}
          >
            <GrMap />
          </NavLink>
					<RoleGate allowedRole = {allowedRole}>
					<NavLink path={appRoutes.users} linkText={t('Χρήστες')} showNavBar={showNavBar}>
            <FiUsers />
          </NavLink>
          <NavLink path={appRoutes.news} linkText={t('Νέα')} showNavBar={showNavBar}>
            <IoNewspaperOutline className='news' />
          </NavLink>
          <NavLink path={appRoutes.gallery} linkText={t('Gallery')} showNavBar={showNavBar}>
            <BsCardImage className='gallery' />
          </NavLink>
					</RoleGate>
        </Styled.Ul>
				<Styled.Divider showNavBar={showNavBar}/>
				<NavLanguageDropdown showNavBar={showNavBar}/>
				<Styled.LogoutButton
				onClick = {logoutHandler}
				showNavBar={showNavBar}
      >
				<span className='button-text'><FiLogOut className='logout' size = {23}/>{t('Logout')}</span>
      </Styled.LogoutButton>
      </Styled.Nav>
      <Styled.NavButton showNavBar={showNavBar} onClick={navBarHandler}>
        <BsChevronRight className='chevron' />
      </Styled.NavButton>
    </Styled.NavContainer>
  )
}

export default Navigation
