interface Props {
  children: React.ReactNode
	allowedRole: 'ADMINISTRATOR' | null
}

const RoleGate = ({ children, allowedRole }: Props) => {

  if (allowedRole) {
    return (
      <>
        {children}
      </>
    )
  }
  return null;
}

export default RoleGate