import axios from './api'
import tokenService from './tokenService'
import { logoutUser, refreshToken } from '../store/auth/auth-slice'
import { toast } from 'react-toastify'
import { AppDispatch } from '../store/store'
import { options } from '../helpers/toastify-options'

const setupInterceptor = (dispatch: AppDispatch) => {
  axios.interceptors.response.use(
    response => response,
    async error => {
      const originalConfig = error.config
      const customId = 'refreshTokenMsgId' //Prevents duplicate toastify messages

      //Prevents infinite loop if the refresh token is invalid or expired
      if (error.response.status === 401 && originalConfig.url === '/login/refresh/') {
        const resfreshToken = toast.loading('Παρακαλώ περιμένετε...', {
          toastId: customId,
        })
        dispatch(logoutUser())
        toast.update(resfreshToken, {
          render: 'Η συνεδρία σας έληξε, παρακαλώ συνδεθείτε.',
          type: 'error',
          ...options,
        })

        return Promise.reject(error)
      }

      // Refresh access token
      if (originalConfig.url !== '/login/' && error.response) {
        if (error.response.status === 401 && !originalConfig._retry) {
          const resfreshToken = toast.loading('Παρακαλώ περιμένετε...', {
            toastId: customId,
          })
          originalConfig._retry = true
          const response = await axios.post('/login/refresh/', {
            refresh: tokenService.getLocalRefreshToken(),
          })

          setTimeout(() => {
            toast.update(resfreshToken, {
              render: 'Η συνεδρία σας ανανεώθηκε επιτυχώς',
              type: 'success',
              ...options,
            })
          }, 3000)

          tokenService.updateLocalAccessToken(response.data.data.access)
          dispatch(refreshToken())

          return axios(originalConfig)
        }
      }
      return Promise.reject(error)
    }
  )
}

export default setupInterceptor
